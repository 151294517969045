class VHfix {
    constructor() {
        // Gget the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;

        // Sset the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', '${vh}px');

        // Listen to resize
        window.addEventListener('resize', () => {
            // Execute the same script
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', '${vh}px');
        });
    }
}

export default VHfix